import { Select } from '@components'
import { Input } from '@elements'
import { useGraphqlCheckout, useGraphqlPurchaseRequest, useLocationWithState, useWindowSize } from '@hooks'
import BackArrow from '@images/back-arrow.svg'
import PriceTagIcon from '@images/price-tag.svg'
import { CheckoutLayout } from '@layouts'
import { user as graphqlUser } from '@graphql'
import {
  AddressCard,
  GolfClubModel,
  GolfEquipmentModel,
  PaymentCard,
  ProductPageItemType,
  ResponseType,
  CacheUser,
  PurchaseRequest
} from '@types'
import { addDays } from '@utils'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Button, Tooltip } from '@elements'

interface CheckoutReviewBuyPriceProps {
  id: string
  type: 'golfClub' | 'equipment'
}

const EXPIRATION_DAYS = [
  { label: '0 Days', value: '0' },
  { label: '48 Hours', value: '2' },
  { label: '10 Days', value: '10' },
  { label: '20 Days', value: '20' },
  { label: '30 Days', value: '30' }
]

/**
 * Used to update an existing offer on a club or item
 */
const CheckoutReviewBuyPrice: React.FC<CheckoutReviewBuyPriceProps> = ({ id, type }) => {
  const { updatePurchaseRequest, loading: updatePurchaseRequestLoading } = useGraphqlPurchaseRequest()
  const { state } = useLocationWithState<{ viewPage: boolean; type: ProductPageItemType }>()
  const [golfModel, setGolfModel] = useState<GolfEquipmentModel | GolfClubModel>()
  const [selectedExpirationDays, setSelectedExpirationDays] = useState<string>(EXPIRATION_DAYS[0].value)
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  const { golfType, golfTypeData, loading } = useGraphqlCheckout(id, type)

  const { data: currentUserData } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })
  const user = currentUserData && currentUserData.res

  const existingOfferData: PurchaseRequest | undefined = golfType?.openPurchaseRequests.find(
    pr => pr.user.id === user?.id
  )

  useEffect(() => {
    if (golfType) {
      if ('golfClubModel' in golfType) setGolfModel(golfType.golfClubModel)
      else if ('golfEquipmentModel' in golfType) setGolfModel(golfType.golfEquipmentModel)

      if (existingOfferData) {
        setNewGolfClubPrice(existingOfferData.itemPrice)
      }
    }
  }, [golfType])

  const handleConfirm = async (addressCard?: AddressCard, userCard?: PaymentCard) => {
    const expirationDaysNumber = +selectedExpirationDays
    const expiresAt = addDays(
      existingOfferData && existingOfferData.expiresAt ? new Date(existingOfferData.expiresAt) : new Date(),
      expirationDaysNumber
    )
      .toISOString()
      .split('T')[0]
    const price = newGolfClubPrice
    const returnPayload = () => {
      if (golfType && expiresAt && price && addressCard && userCard) {
        if (golfModel?.__typename === 'GolfClubModel')
          return {
            addressCardId: addressCard.id,
            expiresAt,
            golfClubTypeId: golfType?.id,
            price,
            paymentCardId: userCard.id
          }
        return {
          addressCardId: addressCard.id,
          expiresAt,
          golfEquipmentTypeId: golfType?.id,
          price,
          paymentCardId: userCard.id
        }
      }
    }
    const payload = returnPayload()
    if (payload && existingOfferData?.id)
      await updatePurchaseRequest({
        id: existingOfferData?.id,
        purchaseRequest: payload
      }).then(() => navigate(-1))
  }

  const [newGolfClubPrice, setNewGolfClubPrice] = useState<number | undefined>(existingOfferData?.itemPrice)

  // format will return a string for display, otherwise it returns a number for calculations
  const getlowestSellerPrice = (format = false) => {
    const prices: number[] = []
    if (golfType) {
      if ('golfClubModel' in golfType) {
        golfType.golfClubsOnSale.forEach(club => {
          prices.push(club.price)
        })
      } else if ('golfEquipmentModel' in golfType) {
        golfType.golfEquipmentsOnSale.forEach(eq => {
          prices.push(eq.price)
        })
      }
      if (format) {
        return prices.length > 0 ? `$${Math.min(...prices)}` : 'None'
      }
      return prices.length > 0 ? Math.min(...prices) : 1
    }
  }

  const getHighestOfferText = () => {
    const offers: number[] = []
    golfType?.openPurchaseRequests.forEach(pr => {
      offers.push(pr.itemPrice)
    })

    return existingOfferData?.itemPrice && existingOfferData?.itemPrice === Math.max(...offers)
      ? 'You have the highest offer!'
      : `Someone else has a higher offer: $${Math.max(...offers)}`
  }

  const getOfferPercentDifference = () => {
    const price = existingOfferData?.itemPrice
    const lowest = getlowestSellerPrice()

    if (lowest === 1) {
      return
    }

    let percentDiff = 0
    if (typeof price === 'number' && typeof lowest === 'number') {
      percentDiff = Math.round(((lowest - price) / ((price + lowest) / 2)) * 100)
    }

    return percentDiff >= 0 ? ` (+${percentDiff}%)` : ` (${percentDiff}%)`
  }

  const suggestedPercents = [5, 10, 20]
  const handleSetSuggested = (percent: number) => {
    if (existingOfferData?.itemPrice) {
      setNewGolfClubPrice(Math.ceil(existingOfferData?.itemPrice + existingOfferData?.itemPrice * (percent / 100)))
    }
  }

  return (
    <CheckoutLayout
      golfTypeData={golfTypeData}
      golfTypeLoading={loading}
      handleConfirm={handleConfirm}
      price={newGolfClubPrice}
      title={
        <strong className="checkout-payment-info-title subtitle1">
          {isMobileRender && <BackArrow onClick={() => navigate(-1)} />}
          <PriceTagIcon />
          Review Existing Offer
        </strong>
      }
      mutationLoading={updatePurchaseRequestLoading}
    >
      <div className={'checkout-payment-note'}>
        We suggest getting within 20% of the Buy it Now price. Seller(s) will be notified about the new highest offer.
      </div>
      <div className={'checkout-payment-review-offer'}>
        <div className={'checkout-payment-review-offer-info'}>
          <span style={{ color: 'darkgrey' }}>OFFER EXPIRES: {existingOfferData?.expiresAt}</span>

          <div className={'checkout-payment-review-offer-info-split'}>
            <div>
              This is your current offer: ${existingOfferData?.itemPrice}
              <br />
            </div>
            <div style={{ color: '#AF3737' }}>
              Buy it Now Price: {getlowestSellerPrice(true)} {getOfferPercentDifference()}
            </div>
          </div>
          <span style={{ color: 'darkgrey' }}>{getHighestOfferText()}</span>
        </div>
        <div className={'checkout-payment-review-offer-suggested'}>
          <div className={'checkout-payment-review-offer-suggested-percents'}>
            {suggestedPercents.map(percent => {
              const buttonText = `+ ${percent}%`
              return (
                <Button size={'lg'} type={'roundBlack'} key={percent} onClick={() => handleSetSuggested(percent)}>
                  {buttonText}
                </Button>
              )
            })}
            {/* <Tooltip>Neat Tooltip</Tooltip> */}
          </div>
          <div className={'checkout-payment-review-offer-suggested-note'}>
            Increase your current offer by a suggested % or enter a new offer below
          </div>
        </div>
      </div>
      <div className="checkout-payment-info-set-price-form">
        <div className="checkout-payment-info-set-price-form-field">
          <label>New Offer</label>
          <Input
            placeholder="$0.00"
            value={newGolfClubPrice?.toString()}
            onChange={val => {
              if (+val < 1000000) {
                setNewGolfClubPrice(+val)
              }
            }}
            type="text"
            theme="green"
          />
          <div className={'checkout-payment-info-set-price-form-field-warn'}>
            *You must meet the minimum offer of $15
          </div>
        </div>
        <div className="checkout-payment-info-set-price-form-field">
          <label>extend expiration by</label>
          <Select
            name="expiration"
            options={EXPIRATION_DAYS}
            value={selectedExpirationDays}
            onChange={val => val && setSelectedExpirationDays(val)}
          />
        </div>
      </div>
    </CheckoutLayout>
  )
}

export default CheckoutReviewBuyPrice
